// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://localhost:44363',
  //baseUrl: 'https://localhost:5001',
  baseUrl: 'https://ms.stagingsdei.com:8003',
 
//baseUrl: 'https://ms.stagingsdei.com:8003',
};
//https://ms.stagingsdei.com:8003/swagger/index.html
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


export const organization = {
  getOrganizationAllAPI: '/api/Organization/GetOrganizationList',
  getOrganizationDetailsAPI: '/api/EnrollInstitute/',
  addOrganizationAPI: '/api/EnrollInstitute',
  updateOrganizationAPI: '/api/EnrollInstitute/Update',
  getOrganizationByIDAPI: '/api/EnrollInstitute/GetById',
  allowedNotAllowedAPI: '/api/Organization/AllowedNotAllowedDemo',
  enableNotEnabledAPI: '/api/Organization/EnableDisableOrganization',
  resetPassword: '/api/Organization/ResetPassword',
  GetAllowedNotAllowedDemoFromMasterTable: '/api/Organization/GetAllowedNotAllowedDemoFromMasterTable',
  SetAllowedNotAllowedDemoFromMasterTable: '/api/Organization/SetAllowedNotAllowedDemoFromMasterTable',
  getActivecustomgrid: '/api/EnrollInstitute/GetEnrollInstitueCustomGrid',
  addActiveCustomGrid: '/api/EnrollInstitute/CreateEnrollInstitueCustomGrid',
  getEnquirycustomGrid: '/api/EnrollInstitute/GetEnrollInstitueCustomGridForDemo',
  addEnquirycustomGrid: '/api/EnrollInstitute/CreateEnrollInstitueCustomGridForDemo',
  RaisedOrganizationInvoice: '/api/Organization/RaisedOrganizationInvoice',
  GetOrgTransactionById: '/api/Transaction/GetOrgTransactionById',
  checkSubdomainAPI: '/api/EnrollInstitute/CheckSubdomain',
};

export const siteSubscriptionPlan = {

  addSubscriptionPlanAPI: '/api/SubscriptionPlan',
  allSubscriptionPlanAPI: '/api/SubscriptionPlan/GetSubscriptionList',
  getSubscriptionPlanByIdAPI: '/api/SubscriptionPlan/',
  updateSubscriptionPlanAPI: '/api/SubscriptionPlan/Update',
  deleteSubscriptionPlanAPI: '/api/SubscriptionPlan/delete',
  addPlanTypeAPI: '/api/SubscriptionPlan/AddSubscriptionPlanType',
};

export const siteEmailSubscriptionPlan = {

  addEmailSubscriptionPlanAPI: '/api/EmailSubscriptionPlan',
  allEmailSubscriptionPlanAPI: '/api/EmailSubscriptionPlan/GetAll',
  getEmailSubscriptionPlanByIdAPI: '/api/EmailSubscriptionPlan/',
  updateEmailSubscriptionPlanAPI: '/api/EmailSubscriptionPlan/Update',
  deleteEmailSubscriptionPlanAPI: '/api/EmailSubscriptionPlan/Delete',
};

export const siteSMSSubscriptionPlan = {

  addSMSSubscriptionPlanAPI: '/api/SMSSubscriptionPlan',
  allSMSSubscriptionPlanAPI: '/api/SMSSubscriptionPlan/GetSMSSubscriptionPlanList',
  getSMSSubscriptionPlanByIdAPI: '/api/SMSSubscriptionPlan/',
  updateSMSSubscriptionPlanAPI: '/api/SMSSubscriptionPlan/Update',
  deleteSMSSubscriptionPlanAPI: '/api/SMSSubscriptionPlan/Delete',
};

export const roles = {
  allSubscriptionPlanAPI: '/api/Roles/GetRolesList',
  getRolesDropdown: '/api/Master/RolesDropdown',
  GetRoleScreens: '/api/Roles/GetRoleScreens',
  postRole: '/api/Roles',
  GetEditRoleScreens: '/api/Roles/GetEditRoleList',
  editRole: '/api/Roles/EditRole',
  deleteRole: '/api/Roles/DeleteRole',
};


export const emailTemplate = {
  allEmailTemplateAPI: '/api/EmailTemplate/GetEmailTemplateList',
  getEmailTemplateByIdAPI: '/api/EmailTemplate/',
  updateEmailTemplateAPI: '/api/EmailTemplate/Update',
  customEmailTemplateAPI: '/api/EmailTemplate',
  GetAllEmailTemplateAPI: '/api/EmailTemplate',
  DeleteEmailTemplateAPI: '/api/EmailTemplate/Delete',
};

export const adminUsers = {
  alladminUsersAPI: '/api/AdminUsers/GetAdminUserList',
  getEmailTemplateByIdAPI: '/api/EmailTemplate/',
  updateEmailTemplateAPI: '/api/EmailTemplate/',
  addStaffAPI: '/api/AdminUsers',
  getStaffByIdAPI: '/api/AdminUsers/',
  updateStaffAPI: '/api/AdminUsers/Update',
  deleteStaffAPI: '/api/AdminUsers/Delete',
  resetPasswordAPI: '/api/AdminUsers/ResetPassword',
  changePasswordAPI: '/api/AdminUsers/Change-Password',
  getcustomGrid: '/api/AdminUsers/GetAdminCustomGridId',
  addCustomGrid: '/api/AdminUsers/CreateAdminCustomGrid',
  checkDuplicateUserName: '/api/AdminUsers/CheckDuplicateUsername',
  checkEmail: '/api/AdminUsers/CheckUserEmail',
  forgotPassword: '/api/AdminUsers/ForgotAdminPassword',

};

export const masters = {
  getCountryDropdown: '/api/Master/CountryDropdown',
  getStateDropdown: '/api/Master/StateDropdown',
  getBloodGroupDropdown: '/api/Master/BloodGroupDropdown',
  getPlanTypeDropdown: '/api/Master/PlanTypeDropdown',
  getPlanTypeStatusDropdown: '/api/Master/PlanTypeStatusDropdown',
  getGenderDropdownDropdown: '/api/Master/GenderDropdown',
  getSiteSubscriptionPlanDropdown: '/api/Master/SiteSubscriptionPlanDropdown',
  getSMSSubscriptionPlanDropdown: '/api/Master/SMSSubscriptionPlanDropdown',
  getEmailSubscriptionPlanDropdown: '/api/Master/EmailSubscriptionPlanDropdown',
  organizationTypeAPI: '/api/Master/OrganizationTypeDropdown',
  allowedStudentAPI: '/api/Master/MaximumStudentDropdown',
  getEnquiryStatusDropdown: '/api/Master/EnquiryStatusDropdown',
  getDataLogs: '/api/Master/GetAppLogs',
  getRolesList: '/api/Roles/GetRolesList',
  alladminUsersList: '/api/AdminUsers/GetAdminUserList',
};


export const profile = {
  getProfilePhoto: '/api/Account/UploadPhoto'
};


export const Transaction = {
  allTransactionAPI: '/api/Transaction/GetTransactionList',
  getTransactionDetailsByIdAPI: '/api/Transaction/'
};

export const AccountLogin = {
  loginAPI: '/api/Account/login',
  logoutAPI: '/api/Account/UserLogout',
  menuListAPI: '/api/Roles/GetRolesById',
};

export const Enquiries = {
  getEnquiriesList: '/api/Enquiry/GetEnquiriesList',
  getEnquiryDetailsByIdList: '/api/Enquiry/',
  allowedDemoAPI: '/api/Enquiry/AllowedDemo',
  denyDemoAPI: '/api/Enquiry/DenyDemo',
  sendResponseAPI: '/api/Enquiry/EnquiryResponse',
  getResponseAPI: '/api/Enquiry/GetResponseList'
};
export const ViewAdminMasters = {
  getAdminList: '/api/AdminUsers/AdminDetails',


}
export const contentManagement = {
  postBannerImages: '/api/ContentManagement/UploadBannerImages',
  postBannerData: '/api/ContentManagement/AddHomePage',
  updateBannerData: '/api/ContentManagement/UpdateHomePage',
  getBannerData: '/api/ContentManagement/GetHomePage',
  postQuoteData: '/api/ContentManagement/AddQuote',
  getQuoteData: '/api/ContentManagement/GetQuoteList',
  deleteQuoteData: '/api/ContentManagement/DeleteQuote',
  updateQuoteData: '/api/ContentManagement/UpdateQuote',
  getByIDQuoteData: '/api/ContentManagement/GetQuoteById',
  postFeatureImages: '/api/ContentManagement/UploadFeatureImages',
  postFeatureData: '/api/ContentManagement/AddFeature',
  updateFeatureData: '/api/ContentManagement/UpdateFeature',
  updateFeatureBasicData: '/api/ContentManagement/UpdateFeatureBasic',
  getFeatureData: '/api/ContentManagement/GetFeature',
  getFeauteListData: '/api/ContentManagement/GetFeatureList',
  getFeauteDetailsByIdData: '/api/ContentManagement/GetFeatureDetailsById',
  deleteFeatureDetailsById: '/api/ContentManagement/DeleteFeature',
  postPriceData: '/api/ContentManagement/AddPriceContent',
  getPriceData: '/api/ContentManagement/GetPriceContent',
  postPriceImages: '/api/ContentManagement/UploadPriceImages',
  postContactUS: '/api/ContentManagement/AddContactUsContent',
  getContactUSData: '/api/ContentManagement/GetContactUsContent',

  postPolicy: '/api/PrivacyPolicy/AddPrivacyPolicy',
  getPolicy: '/api/PrivacyPolicy/GetPrivacyPolicy',
  postTerms: '/api/TermsOfUse/AddTermsOfUse',
  getTerms: '/api/TermsOfUse/GetTermsOfUse',

  postOBEImages: '/api/ContentManagement/UploadOBEImages',
  postOBEdata: '/api/ContentManagement/AddOBEContent',
  getOBEData: '/api/ContentManagement/GetOBEList',
  getOBEFeatureByIdData: '/api/ContentManagement/GetOBEContentById',
  deteleOBEFeatureByIdData: '/api/ContentManagement/DeleteOBEFeatureById',
  postAboutUsData: '/api/ContentManagement/AddAboutUsContent',
  postAboutImages: '/api/ContentManagement/UploadAboutUsImages',
  getAboutUsData: '/api/ContentManagement/GetAboutUsContent',
  postCustomerImages: '/api/ContentManagement/UploadCustomerImages',
  postCustomerSay: '/api/ContentManagement/AddACustomerSayContent',
  getByIDCustomerData: '/api/ContentManagement/GetCustomerSayById',
  getCustomerData: '/api/ContentManagement/GetCustomerSayList',
  deleteCustomerData: '/api/ContentManagement/DeleteCustomerById',
  postPartnerImages: '/api/ContentManagement/UploadPartnerImages',
  postPartnerSay: '/api/ContentManagement/AddPartnerContent',
  getPartnerData: '/api/ContentManagement/GetPartnersList',
  deletePartnerData: '/api/ContentManagement/DeletePartnersById',
  getByIDPartnersData: '/api/ContentManagement/GetPartnersById',
  postRequestDemoData: '/api/ContentManagement/AddRequestDemoContent',
  postRequestDemoImages: '/api/ContentManagement/UploadRequestDemoImages',
  getRequestDemoData: '/api/ContentManagement/GetRequestDemoContent',
  setSocialMediaLink: '/api/ContentManagement/SetSocialMediaLink',
  getAllSocialMediaLink: '/api/ContentManagement/GetAllSocialMediaLink',
};

export const Dashboard = {
  DashboardStats: '/api/Dashboard',
  GetDashboardAmmountStats: '/api/Dashboard/GetDashboardAmmountStats',
};
